import React, { Component } from 'react'
import * as logo from '../../styles/img/LogoMakr_25Gs2J.png'

export default class Header extends Component {
  render() {
    return (
      <div>
        <a href="https://youtu.be/dQw4w9WgXcQ">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <p className="App-header-text">
          Max van Deurzen
          <br/>
           Developer / Historian / Musician / Poet          
        </p>
      </div>   
    )
  }
}
