import React, { Component } from 'react';
import './App.css';
import Header from './components/Layout/Header'

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
        <Header />
        </div>
        <p className="App-text-box">Hello and welcome to my site. Click on one of the objects to learn more about me.</p>
      </div>
    );
  }
}

export default App;
